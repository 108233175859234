import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let theme = createMuiTheme({
  typography: {
    fontFamily: 'Arvo, sans-serif',
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#5A6364',
    },
    secondary: {
      main: '#fff',
    },
    text: {
      primary: '#5A6364',
      secondary: '#fff',
    },
    background: {
      default: '#FFF',
    },
  },
});

theme.overrides = {
  MuiContainer: {
    root: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: '3vw',
        paddingRight: '3vw',
      },
    },
  },
  MuiTypography: {
    h1: {
      fontFamily: 'Lato, sans-serif',
      fontWeight: '400',
      fontSize: '3rem',
      letterSpacing: '4px',
      textTransform: 'uppercase',
      [theme.breakpoints.up('lg')]: {
        fontSize: '3.5rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '3.5rem',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '3.5rem',
      },
    },
    h2: {
      fontFamily: 'Lato, sans-serif',
      fontWeight: '400',
      fontSize: '2rem',
      letterSpacing: '3px',
      textTransform: 'uppercase',
      [theme.breakpoints.up('lg')]: {
        fontSize: '2.5rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '2.5rem',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '2.5rem',
      },
    },
    h3: {
      fontFamily: 'Lato, sans-serif',
      fontWeight: '400',
      fontSize: '1.5rem',
      letterSpacing: '3px',
      textTransform: 'uppercase',
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.5rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.5rem',
      },
    },
    h4: {
      fontFamily: 'Lato, sans-serif',
      fontWeight: '400',
      textTransform: 'uppercase',
      fontSize: '1.3rem',
    },
    h5: {
      fontFamily: 'Lato, sans-serif',
      fontWeight: '400',
      textTransform: 'uppercase',
      fontSize: '1.3rem',
    },
    h6: {
      fontFamily: 'Lato, sans-serif',
      fontWeight: '400',
      textTransform: 'uppercase',
      fontSize: '1.3rem',
    },
    body1: {
      fontSize: '1rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.3rem',
      },
    },
    body2: {
      fontFamily: 'Lato, sans-serif',
      fontSize: '1.2rem',
    },
    caption: {
      fontFamily: 'Lato, sans-serif',
      fontSize: '0.8rem',
    },
  },
};

theme = responsiveFontSizes(theme);

export default theme;
